<template>
  <div id="page-user-list">
    <div class="flex flex-wrap items-center">
      <!-- ITEMS PER PAGE -->
      <div class="flex-grow">
        <div class="vx-row">
          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="PlusCircleIcon"
              icon-right
              :statistic="currency(deposit)"
              statisticTitle="DEPOSIT BALANCE"
              color="warning"
            />
          </div>

          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="MinusCircleIcon"
              icon-right
              :statistic="currency(withdraw)"
              statisticTitle="WITHDRAW BALANCE"
              color="danger"
            />
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import axios from '../../axios'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
export default {
  components: {
    StatisticsCardLine,
    vSelect,
    flatPickr
  },
  data () {
    return {
      deposit: 0.0,
      withdraw: 0.0,
      textSearch: '',
      startAmont: '',
      endAmont: '',
      startDate: this.moment()
        .subtract(1, 'days')
        .startOf('day')
        .format('YYYY-MM-DD HH:mm:ss'),
      endDate: this.moment().format('YYYY-MM-DD HH:mm:ss'),
      filterType: 0,
      filterSelectType: [
        {
          val: 0,
          name: 'All',
          sentname: 'ALL'
        },
        {
          val: 1,
          name: 'Deposit',
          sentname: 'DEPOSIT'
        },
        {
          val: 2,
          name: 'Withdraw',
          sentname: 'WITHDRAW'
        }
      ],
      filterStatus: 4,
      filterSelectStatus: [
        {
          val: 0,
          name: 'All',
          sentname: 'ALL'
        },
        {
          val: 1,
          name: 'CREATE',
          sentname: 'CREATE'
        },
        {
          val: 2,
          name: 'WAIT_CONFIRM',
          sentname: 'WAIT_CONFIRM'
        },
        {
          val: 3,
          name: 'SUCCESS',
          sentname: 'SUCCESS'
        },
        {
          val: 4,
          name: 'SUCCESS & WAIT_CONFIRM',
          sentname: 'SUCCESS&CONFIRM'
        },
        {
          val: 5,
          name: 'REFUSE',
          sentname: 'REFUSE'
        },
        {
          val: 6,
          name: 'FAILED',
          sentname: 'FAILED'
        },
        {
          val: 7,
          name: 'โยกเข้าร้านค้า',
          sentname: 'MOVE'
        },
        {
          val: 8,
          name: 'จับคู่อัติโนมัติ',
          sentname: 'AUTOMATCH'
        }
      ],
      config: {
        enableTime: true
      },
      dataTransaction: []
    }
  },
  async mounted () {
    await this.getBalance()
  },
  methods: {
    clearSearchBox () {
      this.startDate = this.moment()
        .subtract(1, 'days')
        .startOf('day')
        .format('YYYY-MM-DD HH:mm:ss')
      this.endDate = this.moment().format('YYYY-MM-DD HH:mm:ss')
      this.filterType = 0
      this.filterStatus = 4
      this.textSearch = ''
      this.startAmont = ''
      this.endAmont = ''
    },
    async getBalance () {
      try {
        const getBalance = await axios
          .post('/fpayment/api/balance')

        if (getBalance.data.status.success) {
          const { balance, balance_withdraw } = getBalance.data.data
          this.deposit = balance
          this.withdraw = balance_withdraw
        }
      } catch (e) {
        console.error(e)
      }
    },
    currency (amount) {
      amount = (amount * 1.0).toFixed(2)
      //SET NULL OR NaN = 0
      if (!amount || amount === 'NaN') {
        amount = 0
      }
      if (amount >= 0) {
        if (typeof amount === 'number') {
          amount = String(Math.abs(amount))
        }
        return amount.replace(/\d(?=(\d{3})+\.)/g, '$&,')
      } else {
        amount = String(amount)
        return amount.replace(/\d(?=(\d{3})+\.)/g, '$&,')
      }
    }
  }
}
</script>

<style scoped>
.b {
  border-radius: 5px;
  padding: 5px; /* Add padding here */
  color: white;
  background-color: #792cffde;
}

.b-success {
  background-color: #03c837de;
}

.b-failed {
  background-color: #ff0000de;
}

.b-create {
  background-color: #ffb300de;
}
</style>
